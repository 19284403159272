<template>
  <v-card>
    <v-card-text>
      <v-switch
        aut-header-toggle
        dense
        v-model="display"
        label="Display Header"
      ></v-switch>
      <v-text-field
        outlined
        dense
        aut-document-text
        v-model="documentTitle"
        label="Document Title"
        hint="Displayed as the title of the tab"
        persistent-hint
      />
      <v-text-field
        outlined
        dense
        v-if="display"
        aut-header-text
        v-model="title"
        label="Header Text"
        hint="Displayed as the header title"
        persistent-hint
      />
      <v-text-field
        outlined
        dense
        v-if="display"
        aut-header-height
        v-model="height"
        label="Header Height"
        type="number"
      />
      <v-text-field
        outlined
        dense
        aut-custom-header
        clearable
        v-if="display"
        v-model="customHeader"
        hint="Used to replace the header with a custom component. Needs development"
        persistent-hint
        label="Custom Header"
      />
      <ImageField
        v-model="imageObject"
        v-if="display"
        aut-header-image
        :context="context"
        :key="imageKey"
        @change="link = $event"
      />
      <FeatureSwitches
        v-if="display"
        :features="headerFeatures"
        @update="updateSettings"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" aut-update-header @click="updateSkin" class="ml-2"
        >Update</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { set } from "lodash";
import { mapActions } from "vuex";
import FeatureSwitches from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";
import { headerFeatures } from "@/settings";
export default {
  name: "SettingsHeader",
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      documentTitle: this.definition?.document?.title,
      headerFeatures,
      customHeader: this.definition?.header?.custom_header,
      title: this.definition?.header?.text?.content,
      display: this.definition?.header?.display || false,
      imageKey: 1,
      link: this?.definition?.header?.image || "/no_image.jpg",
      height: this?.definition?.header?.height || 50,
      context: "dummy",
    };
  },
  computed: {
    imageObject() {
      return {
        name: "header_image",
        type: "image",
        mode: "input",
        display: {
          embed: {
            width: 300,
          },
        },
        value: this.link,
      };
    },
  },
  components: {
    FeatureSwitches,
    ImageField: () => import("@/components/fields/ImageField/ImageField.vue"),
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateSettings({ name, value }) {
      const settings = {};
      set(settings, name, value);
      this.saveSettingsToDomain(settings);
    },
    updateSkin() {
      const settings = {
        document: {
          title: this.documentTitle,
        },
        header: {
          text: {
            content: this.title,
          },

          display: this.display,
          height: this.height,
          image: this.link,
        },
      };
      if (this.customHeader) {
        settings.header.custom_header = this.customHeader;
      } else {
        settings.header.custom_header = false;
      }
      this.saveSettingsToDomain(settings);
      this.displaySuccessMessage("Header has been updated!");
    },
  },
};
</script>
